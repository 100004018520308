import React from "react"

import Tada from "react-reveal/Tada"
import { StaticImage } from "gatsby-plugin-image"

const Webseite = () => {
  return (
    <div>
      <header class="p-10 min-h-screen flex items-center justify-center bg-teal-400 sixth">
        <div className=" text-gray-600 px-6 lg:px-8 pt-12 pb-20">
          <div className="max-w-screen-xl mx-auto grid grid-cols-2 gap-8 lg:gap-24 md:items-center">
            <div className="col-span-2 md:col-span-1 flex flex-row space-x-2">
              <Tada>
                <StaticImage
                  src="../assets/images/mann.png"
                  alt="portfolio"
                  className="hero-img "
                  placeholder="blurred"
                />
              </Tada>
            </div>
            <div className="col-span-2 md:col-span-1">
              <h2 className="text-2xl sm:text-3xl  lg:text-4xl text-MedienwerkstattDarkBlue font-medium mb-6">
                Wir stehen Ihnen mit Rat und Tat zur Ihrer Webseite. Nehmen Sie
                Kontakt zu uns auf und wir helfen dauerhaft online erfolgreich
                zu sein.
              </h2>
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}

export default Webseite
